<template>
  <div id="app" class="d-flex flex-column justify-content-sm-between vh-100">
    <div class="container flex-shrink-0">
      <nav v-if="!isMobile" class="navbar mb-3">
        <div class="d-flex flex-column">
          <span class="navbar-brand mb-0 h1 title">{{ name }}食用农产品安全监管平台</span>
          <span style="color: #e3e3e3">民以食为天，食以安为先</span>
        </div>
        <!--<button>农证宝登录</button>-->
        <img class="img-fluid" src="@/assets/btn_login.png" @click="login()"/>
      </nav>

      <nav v-if="isMobile" class="navbar navbar-light bg-light mb-3" style="height: 60px">
        <div class="d-flex flex-column">
          <span class="navbar-brand title" style="padding-top: 0; font-size: 18px">{{ name }}食用农产品安全监管平台</span>
          <span style="color: #e3e3e3; font-size: 12px">民以食为天，食以安为先</span>
        </div>
        <!--<span class="navbar-brand title" style="font-size: 20px">{{ name }}食品安全监管平台</span>-->
      </nav>

      <router-view @updateInfo="update" />
    </div>

    <footer class="footer py-3">
      <!--<div class="d-flex justify-content-center">-->
      <!--</div>-->
      <div class="text-center">
        <span v-if="!isMobile" id="footer-text">{{ name }}食用农产品安全监管平台 </span><a href="http://nongzhengbao.com" target="_blank">农销乐©</a> 2021 版权所有 京ICP备案号05008494号
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      name: '农证宝',
      isMobile: false
    }
  },
  created() {
    this.isMobile = this.isMobileDevice()
  },
  methods: {
    update: function(info) {
      // console.log('update', info)
      this.name = info.nick
    },
    login: function() {
      // console.log('login')
      window.location.href = 'https://admin.nongzhengbao.com'
    },
    isMobileDevice: function() {
      // console.log(window.innerWidth, window.innerHeight)
      // return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) )
      console.log(navigator.userAgent)
      return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #2c3e50;
  height: 100%;
}

nav.navbar {
  background-image: url('assets/banner.jpg');
  width: 100%;
  height: 120px;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  //padding: 50px;
}

.navbar-brand {
  line-height: 1 !important;
}

span.title {
  align-self: center;
  font-size: 42px;
  color: #00366E;
  text-shadow: 0 0 9px white;
  animation:  glow 3s infinite;
}
@keyframes glow {
  //0% {
  //  text-shadow:  0 0 10px white;
  //}
  //
  //50% {
  //  text-shadow: 2px 2px 10px rgba(255, 255, 255, 1),
  //  -2px -2px 10px rgba(255, 255, 255, 1);
  //}
  0% {
    text-shadow:  0 0 10px white;
  }
  15% {
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 1),
    -2px -2px 10px rgba(255, 255, 255, 1);
  }
  30% {
    text-shadow: 2px 2px 4px rgba(255, 255, 255, .7),
    -2px -2px 4px rgba(255, 255, 255, .7);
  }
  50% {
    text-shadow: 20px 20px 50px rgba(255, 255, 255, .5),
    -20px -20px 50px rgba(255, 255, 255, .5);
  }
}

.block {
  background: white;
  padding: 1rem;
  box-shadow: 0 0 0.5rem #ccc;
}

.block-header {
  line-height: 2.1rem;
  border-bottom: #0E396E solid 1px;
  margin-bottom: 10px;
}

.block-header-text {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0E396E;
  border-bottom: #0E396E solid 4px;
  padding-bottom: 0.25rem;
}

footer {
  //position: absolute;
  //bottom: 0;
  width: 100%;
  height: 60px;
  background: #222222;
  color: white;
  //margin-top: 60px;
}
</style>
