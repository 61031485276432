import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import vRegion from 'v-region'
import '@/styles/styles.scss'

Vue.prototype.$BASE_URL = 'https://api.nongzhengbao.com'
// Vue.prototype.$BASE_URL = 'https://api.yz.nongxiao123.com/foodsafe'
// Vue.prototype.$BASE_URL = 'http://192.168.1.13:8088/foodsafe'
Vue.prototype.$UPLOAD_PREFIX = 'https://res.ncl18.com/'

Vue.config.productionTip = false

Vue.use(vRegion)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
